<template>
  <div>
    <div
      v-if="paymentOptions && paymentOptions.length > 0"
      class="mb-4 flex w-full flex-col"
    >
      <div>Please choose a payment option:</div>
      <div class="w-full divide-y">
        <div>
          <label
            v-for="(po, index) in paymentOptions"
            class="flex w-full space-x-4 py-2"
            :key="`po-${index}`"
            :for="`po-${index}`"
          >
            <div>
              <input
                type="radio"
                :id="`po-${index}`"
                :value="po.id"
                v-model="paymentCard"
              />
            </div>
            <div class="flex-grow">
              <div>{{ po.card | startCase }} **** **** {{ po.last4 }}</div>
              <div class="text-sm text-gray-500">
                Expires {{ po.expMonth }}/{{ po.expYear }}
              </div>
            </div>
          </label>
        </div>
        <div>
          <label for="new" class="flex w-full items-center space-x-4 py-4 pb-2"
            ><input type="radio" id="new" :value="null" v-model="paymentCard" />
            <div>Add a new payment method</div></label
          >
        </div>
      </div>
    </div>
    <div
      v-if="warning"
      class="warning-msg mb-4 space-y-2 rounded border-2 border-red-400 bg-red-100 p-4 py-3 text-sm text-red-400"
    >
      <div class="-ml-4 -mr-4 px-1 px-4 py-2 font-medium">
        <div class="border-l-2 border-red-400 pl-2">
          {{ warning.message }}
        </div>
      </div>
    </div>
    <div
      v-if="error"
      class="error-msg mb-4 space-y-2 rounded border-2 border-red-400 bg-red-100 p-4 py-3 text-sm text-red-400"
    >
      <div>
        Hmmmm something went wrong... We couldn't successfully take payment.
      </div>
      <div class="-ml-4 -mr-4 px-1 px-4 py-2 font-medium">
        <div class="border-l-2 border-red-400 pl-2">
          {{ error.message }}
        </div>
      </div>
      <div class="text-xs italic" v-if="error.code">
        If you continue to have trouble please contact us quoting the following
        error code:<span class="font-mono"> {{ error.code }}</span>
      </div>
    </div>
    <div
      class="rounded bg-gray-200 p-4"
      :class="{ 'pointer-events-none opacity-0': paymentCard }"
    >
      <div id="card-element"></div>
    </div>
    <div id="card-errors" role="alert"></div>

    <form @submit.prevent="addAddress" novalidate>
      <loading-button
        :is-loading="isLoading"
        :is-enabled="isEnabled && !warning"
        class="default-transition w-full"
        :class="paymentCard ? '-mt-12' : 'mt-4'"
        is-full
      >
        <div>Place my order</div>
      </loading-button>
    </form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import LoadingButton from '@/components/LoadingButton';
import { isEmpty, find, last } from 'lodash/fp';
import utils from '@/utils';
import http from '@/http';
export default {
  props: {
    isEnabled: {
      type: Boolean
    },
    address: {
      type: Object
    },
    cats: {
      type: Array
    }
  },
  components: {
    LoadingButton
  },
  data() {
    return {
      error: null,
      warning: null,
      paymentCard: undefined,
      elements: null,
      card: null,
      stripe: null,
      isLoading: false,
      stripeSecret: null
    };
  },
  computed: {
    ...mapGetters({
      tasterType: 'getTasterType',
      products: 'getProducts',
      currentSubscription: 'getCurrentSubscription',
      paymentOptions: 'getPaymentMethodsGetter',
      activeDelivery: 'activeDeliveryOptionGetter'
    }),
    isTaster() {
      return !this.user?.hadSubscription;
    },
    user: function () {
      return this.$store.state.core.user;
    }
  },
  methods: {
    initElements() {
      this.card.mount('#card-element');
      const self = this;
      this.card.on('change', function (event) {
        if (event.brand === 'amex') {
          self.warning = {
            message:
              "Unfortunately we don't accept American Express at this time."
          };
        } else {
          self.warning = null;
        }
      });
    },
    addCard() {
      http.get('/me/wallet/addcard').then((response) => {
        const secret = response.data.content[0].clientSecret;

        this.stripeConfirmCard(secret, this.card);
      });
    },
    addAddress() {
      this.isLoading = true;

      this.address.postcode = this.address.postcode.toUpperCase();

      this.$store
        .dispatch('addAddress', this.address)
        .then(() => {
          if (!this.paymentCard && !this.isTaster) {
            this.addCard();
          } else {
            this.getStripeId();
          }
          if (this.isTaster) {
            const gtag = window.gtag;
            if (gtag && this.isTaster) {
              gtag('event', 'add_shipping_info', {
                value: Number(localStorage.getItem('tasterTotalAmount')),
                currency: 'GBP',
                items: [
                  {
                    item_id: 'taster_box',
                    item_name: 'Taster Box',
                    price: 5,
                    quantity:
                      Number(localStorage.getItem('tasterTotalAmount')) / 5
                  }
                ]
              });
            }
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log('err', error);
          throw Error(error.response.data.error.message);
        });
    },
    catData() {
      const self = this;
      let data = {
        items: this.isTaster
          ? this.cats
          : !isEmpty(this.currentSubscription.items)
            ? this.currentSubscription.items
            : this.cats
      };

      data.items.forEach((v, i) => {
        if (!v.dob) {
          v.dob = '01/01/1970';
        }
        if (!v.name) {
          v.name = 'Cat ' + (i + 1);
        }
        if (!v.gender) {
          v.gender = 'other';
        }
        if (!v.tribe) {
          v.tribe = 'Unknown';
        }
        if (isEmpty(v.products) && self.isTaster) {
          const defaultProduct = find(['sku', 'W_PCH_CHK'], self.products);

          v.products.push({
            sku: v.isKitten ? 'W_PCH_CHK_KIT' : 'W_PCH_CHK',
            quantity: v.foodAmount / defaultProduct.increments
          });
        }

        v.type = v.isKitten ? 'kitten' : 'adult';

        delete v.foodAmount;
        delete v.isKitten;

        const activeCat = find(['id', v.id], self.cats);

        if (activeCat) {
          v.products = activeCat.products;
          v.active = true;
        } else {
          v.active = false;
        }
        if (this.isTaster) {
          v.active = true;
          if (v.sampleboxtype?.type === 'none') {
            v.sampleboxtype = undefined;
          }
          if (i === 0 && data.items.length === 1) {
            v.samplebox = true;
          }
        }
      });

      if (this.isTaster) {
        data.samplebox = true;
        data.ad = last(utils.getStorage('adid', 'local'));
        utils.setStorage('checkoutType', 'Taster', 'local');
      } else {
        utils.setStorage('checkoutType', 'Subscription', 'local');
      }
      if (this.paymentCard) {
        data.pmethod = this.paymentCard;
      }
      return data;
    },
    getStripeId() {
      this.error = null;
      const endpoint = this.isTaster
        ? '/subscription_product_confirm2'
        : '/subscription_product_confirm3';

      const data = this.catData();
      if (this.activeDelivery) {
        data.delivery = this.activeDelivery.prices[0].id;
      }

      if (this.stripeSecret) {
        this.stripeConfirm(this.stripeSecret, this.card);
      } else {
        http
          .post(endpoint, data, {
            params: { type: this.isTaster ? this.tasterType : null }
          })
          .then((response) => {
            if (this.isTaster) {
              this.stripeSecret = response?.data?.content[0]?.id;
              this.stripeConfirm(this.stripeSecret, this.card);
            } else {
              if (response?.data?.content[0]?.clientSecret) {
                this.stripeSecret = response?.data?.content[0]?.clientSecret;
                this.stripeConfirm(this.stripeSecret, this.card);
              } else {
                this.$router.push({ name: 'success' });
              }
            }
          })
          .catch((error) => {
            const errorType = error.response.data.error.type;
            if (errorType === 'authentication_required') {
              this.trigger3ds(
                error.response.data.error.detail,
                error.response.data.error.method
                // 'pm_card_authenticationRequired'
              );
            } else {
              this.isLoading = false;
              this.error = error.response;
            }
          });
      }
    },
    stripeConfirm(secret, card) {
      let stripe = this.stripe;
      let self = this;
      stripe
        .confirmCardPayment(secret, {
          payment_method: {
            card: card,
            billing_details: {
              address: {
                postal_code: self.address.postcode
              }
            }
          }
        })
        .then(function (result) {
          if (result.error) {
            self.error = result.error;
            self.isLoading = false;
          } else {
            self.$store.dispatch('sendMpEvent', {
              event: 'Checkout Success',
              properties: {
                location: self.$route.name,
                type: 'EVT',
                delivery: {
                  cost: self.activeDelivery?.prices[0]?.displayCost,
                  courier: self.activeDelivery?.courier,
                  key: self.activeDelivery?.prices[0]?.id
                }
              }
            });
            if (result.paymentIntent.status === 'succeeded') {
              localStorage.removeItem('checkout');
              self.$router.push({ name: 'success' });
            }
          }
        });
    },
    stripeConfirmCard(secret, card) {
      let stripe = this.stripe;
      let self = this;
      stripe
        .confirmCardSetup(secret, {
          payment_method: {
            card: card,
            billing_details: {
              address: {
                postal_code: self.address.postcode
              }
            }
          }
        })
        .then(function (result) {
          if (result.error) {
            self.error = result.error;
            self.isLoading = false;
          } else {
            self.paymentCard = result.setupIntent.payment_method;

            self.getStripeId();
          }
        });
    },
    trigger3ds(response, method) {
      this.$store.dispatch('sendMpEvent', {
        event: 'Restart Subscription - Showing 3DS',
        properties: {
          location: this.$route.name,
          type: 'EVT'
        }
      });

      const stripe = this.stripe;
      const self = this;
      stripe
        .confirmCardPayment(response, {
          payment_method: method
        })
        .then(function (result) {
          if (result.error) {
            self.clearSubscription();
            if (result?.error?.payment_intent?.last_payment_error?.message) {
              const hasError = result.error.payment_intent.last_payment_error;
              if (hasError.code === 'payment_intent_authentication_failure') {
                console.log('has auth error');
                self.error = {
                  message:
                    'We were unable to authenticate your payment method. Please try again'
                };
                console.log(self.error);
              } else {
                self.error = hasError.message;
              }
            } else {
              self.error =
                'We were unable to authenticate your payment method. Please try again';
            }

            self.hasError = true;
            self.isLoading = false;
            self.$store.dispatch('sendMpEvent', {
              event: 'Restart Subscription - 3DS Fail',
              properties: {
                location: self.$route.name,
                error: result.error.message,
                type: 'EVT'
              }
            });
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              self.$router.push({ name: 'success' });
            }
          }
        });
    },
    clearSubscription() {
      http
        .put('me/clearsubscription')
        .then(() => {
          console.log('sub cleared');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  created() {
    if (!this.isTaster) {
      this.$store.dispatch('getPaymentMethods').then(() => {
        if (!isEmpty(this.paymentOptions)) {
          this.paymentCard = this.paymentOptions[0].id;
        }
      });
    }
  },

  mounted() {
    const self = this;
    this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
    this.elements = this.stripe.elements();
    this.card = this.elements.create('card', {
      hidePostalCode: true,
      postalCode: this.address.postcode
    });

    const observer = new MutationObserver(function (mutations, me) {
      const canvas = document.getElementById('card-element');
      if (canvas) {
        self.initElements(canvas);
        me.disconnect();
        return;
      }
    });

    observer.observe(document, {
      childList: true,
      subtree: true
    });
  }
};
</script>
