2
<template>
  <div>
    <div class="mb-12 px-4 text-center font-merri text-2xl sm:px-8 sm:text-3xl">
      Where shall we send it?
    </div>
    <div class="mx-auto flex w-full flex-col px-2 sm:flex-row sm:px-0 lg:w-2/3">
      <div class="w-full sm:w-1/2">
        <update-address
          v-if="address"
          :address="address"
          @is-valid="checkAddress"
        />
        <div class="input-group">
          <label>Mobile number</label>

          <input
            required
            v-model="address.phone"
            type="text"
            class="fs-exclude"
          />
          <div class="mt-2 text-sm italic text-gray-500">
            Used for delivery updates & order related queries.
          </div>
        </div>
      </div>
      <div class="mb-8 w-full sm:mb-0 sm:ml-8 sm:w-1/2">
        <div v-if="!isTaster">
          <div
            class="-ml-2 -mr-2 rounded-t-lg border-2 border-b-0 p-4"
            v-show="!showDelivery && canDeliver"
          >
            <div class="mb-4 text-lg font-semibold">
              {{
                !postcodeChecker
                  ? 'Choose a delivery service for your monthly box'
                  : 'Delivery service for your monthly box'
              }}
            </div>
            <div class=" ">
              <delivery-options
                v-if="subscriptionCost"
                :postcode="postcodeChecker"
                :subscription-price="subscriptionCost.intTotal"
                @input="setActiveShipping"
                @set-hide="setHide"
                activeOption="dpd_2^12_199"
                reverse
              />
            </div>
          </div>
        </div>
        <div
          v-if="!isTaster"
          class="-ml-2 -mr-2 border-2 border-orange-300 bg-orange-100 p-4 text-center"
        >
          <div>
            We will aim to dispatch your box on
            <strong>{{ deliveryDate }}</strong
            >. Please allow 1 - 3 business days for delivery
          </div>

          <div class="p-4">
            <div>Today you pay</div>
            <div
              v-if="subscriptionCost"
              class=" "
              :class="
                creditCost
                  ? 'mt-2 text-xl font-normal text-gray-600 line-through'
                  : 'text-5xl font-semibold'
              "
            >
              {{ subscriptionCost.total }}
            </div>
            <span v-if="showDeliveryCost">
              (+ {{ activeShipping.prices[0].displayCost }} delivery)</span
            >

            <div
              v-if="creditCost"
              class="mb-4 flex flex-col items-center space-y-2 text-5xl font-semibold"
            >
              <div>{{ creditCost }}</div>
              <div
                class="relative mx-1 flex inline-flex items-center space-x-2 rounded bg-blue-100 p-1 px-2 text-sm font-semibold text-blue-600"
              >
                <font-awesome-icon
                  class="text-blue-600"
                  :icon="['far', 'coins']"
                />
                <div>Credit applied!</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="-ml-2 -mr-2 divide-y-2 rounded-t-lg border-2 border-b-0">
            <div>
              <div class="p-4 py-4">
                <div
                  class="mb-2 flex items-center space-x-2 px-2 text-lg font-semibold"
                >
                  <div class="flex-grow">Your Taster Boxes</div>
                  <div
                    class="text-sm font-normal text-teal-400 underline"
                    @click="changeTasters"
                  >
                    Change
                  </div>
                </div>

                <div class="mb-4 mt-4 divide-y rounded-lg border">
                  <div
                    class="flex items-center space-x-2 px-3 py-2"
                    v-for="(taster, index) in tasterCats"
                    :key="`taster-${index}`"
                  >
                    <div class="flex-grow truncate">{{ taster.name }}</div>
                    <div class="flex-none text-sm text-gray-700">
                      {{
                        typeMapper(taster.sampleboxtype.type, taster.isKitten)
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-6 py-4" v-show="!showDelivery && canDeliver">
              <div class="mb-4 text-lg font-semibold">
                {{
                  !postcodeChecker
                    ? 'Choose a delivery service for your monthly box'
                    : 'Delivery service for your monthly box'
                }}
              </div>
              <div class="-ml-2 -mr-2 mb-4">
                <delivery-options
                  v-if="subscriptionCost"
                  :postcode="postcodeChecker"
                  :subscription-price="subscriptionCost.intTotal"
                  @input="setActiveShipping"
                  @set-hide="setHide"
                  activeOption="dpd_2^12_199"
                  reverse
                />
              </div>
            </div>

            <div class="p-6 py-4">
              <div class="mb-4 text-lg font-semibold">What happens next?</div>

              <div class="space-y-4">
                <div class="flex space-x-4">
                  <div class="font-semibold">1.</div>
                  <div>
                    We will send you your Taster
                    {{ tasterTotal > 5 ? 'Boxes' : 'Box' }} with one of our
                    delivery partners.
                  </div>
                </div>
                <div class="flex space-x-4">
                  <div class="font-semibold">2.</div>
                  <div class="space-y-2">
                    <div>
                      Over the <strong>next 14 days</strong>, try all the
                      different food options, then adjust your order to those
                      they prefer.
                    </div>
                    <div class="italic">
                      Adjust or cancel your subscription anytime.
                    </div>
                  </div>
                </div>
                <div class="flex space-x-4">
                  <div class="font-semibold">3.</div>
                  <div>
                    Sit back and receive healthy food delivered to your door
                    every month!
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="-ml-2 -mr-2 border-2 border-yellow-300 bg-yellow-50 text-left"
          >
            <div class="p-4">
              <div class="text-center">Today you pay</div>
              <div class="text-center text-5xl font-semibold">
                {{ tasterTotal | formatPounds }}
              </div>
              <div class="mt-4" v-if="subscriptionCost">
                Then after 14 days ({{ get14Days }}) you will be charged
                <strong>{{ subscriptionCost.total }}</strong>
                <span v-if="showDeliveryCost">
                  (+ {{ activeShipping.prices[0].displayCost }} delivery)</span
                >
                for your first subscription box. Don't worry we'll be in touch
                with reminders well before we send it out so you have plenty of
                time to easily adjust or cancel.
              </div>
            </div>

            <!-- We'll put the error messages in this element -->
          </div>
        </div>

        <div class="-ml-2 -mr-2 rounded-b-lg border-2 border-t-0 bg-white p-4">
          <div v-if="!canDeliver">
            <div
              class="space-y-4 rounded border-2 border-red-400 bg-red-100 p-4 text-center text-red-500"
            >
              <div>
                It looks like you might be in {{ postcodeChecker.label }}.
                Unfortunately we can't currently deliver to you at this time.
              </div>
              <div>
                Feel free to contact us at
                <a href="mailto:team@thecoolcatclub.com" class="underline"
                  >team@thecoolcatclub.com</a
                >
              </div>
            </div>
          </div>
          <checkout-btn
            v-else
            :is-enabled="isEnabled"
            :address="address"
            :cats="catData"
          />
        </div>

        <p
          class="mt-4 px-2 text-xs text-gray-600"
          v-if="subscriptionCost && canDeliver"
        >
          The formal bit... By clicking "Place my order", you confirm that your
          subscription will automatically renew and your credit card will
          automatically be charged the subscription price of
          {{ subscriptionCost.total }}
          <span v-if="showDeliveryCost"
            >(+ {{ activeShipping.prices[0].displayCost }} delivery)</span
          >
          until you cancel your subscription. You can cancel your subscription
          at any time.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/http';

import UpdateAddress from '@/components/account/UpdateAddress';

import { format, addDays } from 'date-fns';
import utils from '@/utils';
import { mapGetters } from 'vuex';
import CheckoutBtn from '@/components/CheckoutBtn.vue';
import { checkPostcode } from '@/utilities/checkPostcode';
import { clone, isEmpty } from 'lodash/fp';
import DeliveryOptions from '@/components/delivery/DeliveryOptions.vue';
export default {
  metaInfo: {
    title: 'Delivery'
  },
  data() {
    return {
      isLoading: false,
      elements: null,
      card: null,
      address: {
        address1: this.$store.state?.core?.user
          ? this.$store.state?.core?.user?.shippingAddress?.address1
          : '',
        address2: this.$store.state?.core?.user
          ? this.$store.state?.core?.user?.shippingAddress?.address2
          : '',
        address3: this.$store.state?.core?.user
          ? this.$store.state?.core?.user?.shippingAddress?.address3
          : '',
        postcode: this.$store.state?.core?.user
          ? this.$store.state?.core?.user?.shippingAddress?.postcode
          : '',
        phone: this.$store.state?.core?.user
          ? this.$store.state?.core?.user?.phone
          : ''
      },
      addressValid: false,
      checkout: 'Subscription',
      cats: [],

      subscriptionCost: null,
      stripe: null,
      error: null,
      paymentCard: null,
      stripeSecret: null,
      activeShipping: null,
      showDelivery: true,
      hasTastersCost: false,
      tasterTotal: 0
    };
  },
  components: {
    UpdateAddress,
    CheckoutBtn,
    DeliveryOptions
  },
  computed: {
    deliveryDate: function () {
      return format(new Date(this.dispatchDate), 'do MMMM, yyyy');
    },
    dispatchDate() {
      return this.subscriptionCost?.nextDispatchDate
        ? this.subscriptionCost?.nextDispatchDate
        : addDays(new Date(), 1);
    },

    tasterCountValid() {
      let count = 0;
      this.cats.forEach((c) => {
        c.samplebox ? count++ : null;
      });
      return count !== 1;
    },
    user: function () {
      return this.$store.state.core.user;
    },
    isEnabled: function () {
      if (this.addressValid && this.address.phone) {
        return true;
      } else {
        return false;
      }
    },
    showDeliveryCost() {
      return this.activeShipping && this.activeShipping.prices[0].cost > 0;
    },
    isSubscribe: function () {
      return this.$router.currentRoute.meta.isSubscribe;
    },
    ...mapGetters({
      currentSubscription: 'getCurrentSubscription',
      tasterType: 'getTasterType',
      products: 'getProducts',
      tasterPrice: 'getTasterPrice',
      tasterValue: 'getTasterValue',
      paymentOptions: 'getPaymentMethodsGetter',
      tasterBoxes: 'availableTasters'
    }),
    get14Days() {
      return format(addDays(new Date(), 14), 'do MMMM, yyyy');
    },
    postcodeChecker() {
      if (this.address.postcode) {
        return checkPostcode(this.address.postcode, true);
      }
      return false;
    },
    isTaster() {
      return !this.user?.hadSubscription;
    },
    catData() {
      let cats = clone(this.cats);
      if (this.isTaster) {
        cats.forEach((value) => {
          value.products = [];
        });
      } else {
        cats = this.isRestart ? this.$route?.params?.cats || cats : cats;
      }

      return cats;
    },
    creditCost() {
      if (this.currentSubscription.credit > 0) {
        return this.$options.filters.formatPounds(
          this.subscriptionCost.intTotal - this.currentSubscription.credit
        );
      }

      return null;
    },
    canDeliver() {
      if (!this.postcodeChecker) {
        return true;
      }
      return this.postcodeChecker && this.postcodeChecker?.canDeliver;
    },
    isRestart() {
      return this.$route?.query?.restart;
    },
    tasterCats() {
      return this.cats.filter((cat) => cat.samplebox);
    }
  },

  methods: {
    getDispatch() {
      const data = {
        cats: []
      };
      this.cats.forEach((value) => {
        data.cats.push({ products: value.products });
      });

      http
        .post('/subscription_product_preview', data)
        .then((response) => {
          this.dispatchDate = response.data.content[0].nextDispatchDate;
        })
        .catch((err) => {
          throw Error(err.response.data.error.message);
        });
    },

    checkAddress: function (value) {
      this.addressValid = value;
    },

    getSubscriptionCost: function () {
      const data = {
        cats: []
      };
      const self = this;

      let cats;

      if (this.isTaster) {
        cats = this.cats;
      } else {
        cats = this.isRestart
          ? this.$route?.params?.cats || this.cats
          : this.currentSubscription.items;
      }

      cats.forEach((value) => {
        const increment = 5;
        const prods = self.isTaster
          ? [
              {
                sku: 'W_PCH_CHK',
                quantity: value.foodAmount / increment
              }
            ]
          : value.products;

        data.cats.push({
          products: prods
        });
      });
      if (!isEmpty(data.cats)) {
        http
          .post('/subscription_product_preview', data)
          .then((response) => {
            this.isLoading = false;

            this.subscriptionCost = response.data.content[0];
          })
          .catch((err) => {
            this.isLoading = false;
            throw Error(err.response.data.error.message);
          });
      }
    },
    setActiveShipping(val) {
      this.activeShipping = val;
    },
    setHide(val) {
      this.showDelivery = val;
    },
    typeMapper(type, isKitten) {
      const types = {
        both: 'Chicken + Fish Bundle',
        chicken: 'Chicken',
        fish: 'Fish',
        original: isKitten ? 'Kitten' : 'Mixed',
        none: 'None'
      };

      return types[type];
    },
    changeTasters() {
      this.$router.go(-1);
    },
    getTasterTotal() {
      // work out the total cost for all the cats tasters but looking at their sampleboxtype type
      let count = 0;

      this.tasterCats.forEach((c) => {
        const type = this.tasterBoxes.find(
          (t) => t.key === c.sampleboxtype.type
        );
        // console.log('type', type, c.sampleboxtype.type, c.sampleboxtype);

        count += type.cost ? type.cost : 0;
        console.log('count', count, type.cost, type);
      });
      localStorage.setItem('tasterTotalAmount', count / 100);
      this.tasterTotal = count;
      return count;
    }
  },

  mounted() {
    if (this.isTaster) {
      localStorage.setItem('signup', true);
      this.cats = utils.getStorage('cats', 'local') || [];
      this.cats[0].samplebox = true;
      this.getSubscriptionCost();
      this.$store.dispatch('sendFbEvent', {
        type: 'track',
        name: 'InitiateCheckout',
        data: {}
      });

      const gtag = window.gtag;
      if (gtag && this.isTaster) {
        gtag('event', 'begin_checkout', {
          value: Number(localStorage.getItem('tasterTotalAmount')),
          currency: 'GBP',
          items: [
            {
              item_id: 'taster_box',
              item_name: 'Taster Box',
              price: 5,
              quantity: Number(localStorage.getItem('tasterTotalAmount')) / 5
            }
          ]
        });
      }
    } else {
      if (this.$route?.params?.cats) {
        this.cats = this.$route?.params?.cats;
      }
    }

    // this.getDispatch();
    this.$store.dispatch('getSubscription').then(() => {
      if (this.user?.hadSubscription) {
        this.cats = this.currentSubscription.items;
        this.getSubscriptionCost();
      }
    });

    if (!this.products) {
      this.$store.dispatch('getProducts').then(() => {});
    }

    this.$store.dispatch('getTasterBoxesCost').then(() => {
      this.hasTastersCost = true;
      this.getTasterTotal();
    });

    if (klaviyo) {
      klaviyo.push(['track', 'Started Checkout']);
    }
  }
};
</script>

<style lang="css" scoped></style>
